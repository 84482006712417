import { GetRandomColor, GetUnit, HashCode } from '@shared/ColorMath'

const ELEMENTS = 3
const SIZE = 80

function generateColors(name: string, colors: string[]) {
  const numFromName: number = HashCode(name)
  const range = colors?.length

  const elementsProperties = Array.from({ length: ELEMENTS }, (_, i) => ({
    color: GetRandomColor(numFromName + i, colors, range),
    translateX: GetUnit(numFromName * (i + 1), SIZE / 10, 1),
    translateY: GetUnit(numFromName * (i + 1), SIZE / 10, 2),
    scale: 1.2 + GetUnit(numFromName * (i + 1), SIZE / 20) / 10,
    rotate: GetUnit(numFromName * (i + 1), 360, 1)
  }))

  return elementsProperties
}

export default function AvatarMarble({
  title,
  name,
  colors,
  size,
  square = false
}: {
  title?: string
  name: string
  colors: string[]
  size: number
  square?: boolean
}) {
  const properties = generateColors(name, colors)

  // get first letter of name and last name
  const acronym = name
    ?.match(/(\b\S)?/g)
    ?.join('')
    ?.match(/(^\S|\S$)?/g)
    ?.join('')
    ?.toUpperCase()

  return (
    <svg
      viewBox={`0 0 ${SIZE} ${SIZE}`}
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
    >
      <mask
        id="mask__marble"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={SIZE}
        height={SIZE}
      >
        <rect
          width={SIZE}
          height={SIZE}
          rx={square ? undefined : SIZE * 2}
          fill="#FFFFFF"
        />
      </mask>
      <g mask="url(#mask__marble)">
        <rect width={SIZE} height={SIZE} fill={properties[0].color} />
        <path
          filter="url(#prefix__filter0_f)"
          d="M32.414 59.35L50.376 70.5H72.5v-71H33.728L26.5 13.381l19.057 27.08L32.414 59.35z"
          fill={properties[1].color}
          transform={`translate(${String(properties[1].translateX)} ${String(
            properties[1].translateY
          )}) 
            rotate(${String(properties[1].rotate)} ${SIZE / 2} ${
            SIZE / 2
          }) scale(${String(properties[2].scale)})`}
        />
        <path
          filter="url(#prefix__filter0_f)"
          style={{
            mixBlendMode: 'overlay'
          }}
          d="M22.216 24L0 46.75l14.108 38.129L78 86l-3.081-59.276-22.378 4.005 12.972 20.186-23.35 27.395L22.215 24z"
          fill={properties[2].color}
          transform={`translate(${String(properties[2].translateX)} ${String(
            properties[2].translateY
          )}) 
            rotate(${String(properties[2].rotate)} ${SIZE / 2} ${
            SIZE / 2
          }) scale(${String(properties[2].scale)})`}
        />
        <text
          x="50%"
          y="50%"
          text-anchor="middle"
          dominant-baseline="central"
          transform="translate(0,0)"
          fill="#ffffff"
          className="avatar-text"
        >
          {acronym}
        </text>
      </g>
      <defs>
        <filter
          id="prefix__filter0_f"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation={7} result="effect1_foregroundBlur" />
        </filter>
      </defs>
    </svg>
  )
}
