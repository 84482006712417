import BInput from '@components/BInput'
import ChangePassword from '@components/ChangePassword/ChangePassword'
import { SubsectionWrapper } from '@components/SubsectionWrapper'
import { blocksIcons } from '@constants/icons'
import { PwdTypes } from '@models/dtos/ChangePassword'
import Region from '@models/Region'
import { Settings, Language } from '@models/Settings'
import ToMap from '@shared/ToMap'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'

export default function AccountInformation({
  formik,
  disabledInputs = false
}: {
  formik: FormikProps<Settings>
  disabledInputs: boolean
}) {
  const { formatMessage: f } = useIntl()

  // TODO: Get Languages from API
  const languages: Language[] = [
    {
      name: 'English',
      abbreviation: 'en'
    },
    {
      name: 'Spanish',
      abbreviation: 'es'
    }
  ]
  const languagesMap = ToMap(
    languages,
    (lang) => lang.abbreviation,
    (lang) => lang.name
  )

  // TODO: Get Regions from API
  const regions: Region[] = [
    {
      identification: '40',
      name: 'Las Vegas, Nevada'
    },
    {
      identification: '43',
      name: 'Los Angeles, California'
    }
  ]

  const regionsMap = ToMap(
    regions,
    (region) => region.identification,
    (region) => region.name
  )

  return (
    <SubsectionWrapper className="w-full lg:w-[480px]">
      <div className="mb-4 flex gap-6">
        <p className="font-bold">{f({ id: 'Account information' })}</p>
      </div>
      <div className="relative grid grid-cols-1 gap-4">
        <BInput
          disabled={disabledInputs}
          label={f({ id: 'Name' })}
          name="profileSetting.name"
          value={formik.values?.profileSetting?.name}
          onChange={formik.handleChange}
          error={formik.errors?.profileSetting?.name}
          contentLeft={blocksIcons.person}
        />

        {/* <Field
          disabled
          label={f({ id: 'Region' })}
          name="profileSetting.region"
          value={formik.values?.profileSetting?.region.identification}
          items={regionsMap}
          onChange={(value) => {
            const selected = regions.find((x) => x.identification === value)
            formik.setFieldValue('profileSetting.region', selected)
          }}
          as={BSelect}
        /> */}

        {/* <Field
          disabled={disabledInputs}
          label={f({ id: 'Language' })}
          name="profileSetting.language"
          value={formik.values?.profileSetting?.language.abbreviation}
          items={languagesMap}
          onChange={(value) => {
            const selected = languages.find((x) => x.abbreviation === value)
            formik.setFieldValue('profileSetting.language', selected)
          }}
          as={BSelect}
        /> */}

        <ChangePassword
          label={f({ id: 'Password' })}
          text={f({ id: 'Change your password' })}
          type={PwdTypes.UserPwd}
        />
      </div>
    </SubsectionWrapper>
  )
}
