import BInput from '@components/BInput'
import InputExpiryDate from '@components/InputExpiryDate'
import { SubsectionWrapper } from '@components/SubsectionWrapper'
import { blocksIcons } from '@constants/icons'
import { Settings } from '@models/Settings'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'

export default function Billings({
  formik,
  disabledInputs = false
}: {
  formik: FormikProps<Settings>
  disabledInputs: boolean
}) {
  const { formatMessage: f } = useIntl()

  return (
    <SubsectionWrapper className="w-full lg:w-[480px]">
      <div className="mb-4 flex gap-6">
        <p className="font-bold">{f({ id: 'Billings' })}</p>
      </div>
      <div className="relative grid grid-cols-1 gap-4">
        <BInput
          disabled={disabledInputs}
          label={f({
            id: 'Name on card'
          })}
          name="paymentMethod.creditCardSelected.nameOnCard"
          value={formik.values?.paymentMethod?.creditCardSelected?.nameOnCard}
          onChange={formik.handleChange}
          error={formik.errors?.paymentMethod?.creditCardSelected?.nameOnCard}
          contentLeft={blocksIcons.person}
        />
        <BInput
          disabled={disabledInputs}
          label={f({
            id: 'Card number'
          })}
          name="paymentMethod.creditCardSelected.cardNumber"
          value={formik.values?.paymentMethod?.creditCardSelected?.cardNumber}
          onChange={formik.handleChange}
          error={formik.errors?.paymentMethod?.creditCardSelected?.cardNumber}
          contentLeft={blocksIcons.wallet}
        />
        <div className="w-full grid grid-cols-2 gap-4">
          <InputExpiryDate
            disabled={disabledInputs}
            label={f({
              id: 'Expiry date'
            })}
            name="paymentMethod.creditCardSelected.expirationDate"
            value={
              formik.values?.paymentMethod?.creditCardSelected?.expirationDate
            }
            onChange={formik.handleChange}
            error={
              formik.errors?.paymentMethod?.creditCardSelected?.expirationDate
            }
            size="lg"
            placeholder="MM/YY"
          />

          <BInput
            disabled={disabledInputs}
            label={f({
              id: 'Security code'
            })}
            name="paymentMethod.creditCardSelected.cvv"
            value={formik.values?.paymentMethod?.creditCardSelected?.cvv}
            onChange={formik.handleChange}
            error={formik.errors?.paymentMethod?.creditCardSelected?.cvv}
            size="lg"
            placeholder="CVV"
          />
        </div>
        <BInput
          disabled={disabledInputs}
          label={f({
            id: 'ZIP/Postal code'
          })}
          name="paymentMethod.creditCardSelected.postalCode"
          value={formik.values?.paymentMethod?.creditCardSelected?.postalCode}
          onChange={formik.handleChange}
          error={formik.errors?.paymentMethod?.creditCardSelected?.postalCode}
        />
      </div>
    </SubsectionWrapper>
  )
}
