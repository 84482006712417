import BInput from '@components/BInput'
import { blocksIcons } from '@constants/icons'
import { ChangePasswordDto, PwdTypes } from '@models/dtos/ChangePassword'
import { Button, Checkbox, Input, Modal, Row, Text } from '@nextui-org/react'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import * as yup from 'yup'

export default function ChangePasswordModal({
  label,
  open,
  type,
  closeHandler,
  submit
}: {
  label: string
  open: boolean
  type: PwdTypes
  closeHandler: () => void
  submit: (value: ChangePasswordDto, actions: unknown) => void
}) {
  const { formatMessage: f } = useIntl()

  const initializeChangePassword: ChangePasswordDto = {
    newPassword: '',
    type
  }

  const formSchema = yup.object({
    newPassword: yup
      .string()
      .min(8, ({ min }) =>
        f(
          {
            id: 'Password must be at least {min} characters'
          },
          { min }
        )
      )
      .required(
        f({
          id: 'Password is required'
        })
      )
  })

  const submitHandler = async (values: ChangePasswordDto, actions: any) => {
    submit(values, actions)
    console.log('change password')
    closeHandler()
  }

  return (
    <Modal
      closeButton
      aria-labelledby="modal-title"
      open={open}
      onClose={closeHandler}
    >
      <Formik
        initialValues={initializeChangePassword}
        validationSchema={formSchema}
        onSubmit={submitHandler}
      >
        {(formik) => (
          <Form className="w-full">
            <Modal.Header>
              <Text className="font-bold">
                {f({ id: 'modal.change.password.title' })}
              </Text>
            </Modal.Header>
            <Modal.Body className="min-h-[120px]">
              <BInput
                className="w-full"
                /* label={label} */
                placeholder={label}
                value={formik.values.newPassword}
                onChange={formik.handleChange('newPassword')}
                error={formik.errors.newPassword}
                contentLeft={blocksIcons.unlock}
                type="password"
                size="lg"
                bordered
                clearable
              />
            </Modal.Body>
            <Modal.Footer>
              <Button auto type="submit">
                {f({ id: 'modal.change.password.action.btn' })}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}
