import BButton from '@components/bButton/BButton'
import FormField from '@components/FormField'
import { ChangePasswordDto, PwdTypes } from '@models/dtos/ChangePassword'
import { changePwdRequest } from '@store/changePassword/changePassword.slice'
import { useAppDispatch } from '@store/hooks'
import { ReactNode, useState } from 'react'
import { useIntl } from 'react-intl'
import ChangePasswordModal from './ChangePasswordModal'

export default function ChangePassword({
  children,
  label,
  text,
  type
}: {
  children?: ReactNode
  label: string
  text: string
  type: PwdTypes
}) {
  const { formatMessage: f } = useIntl()
  const dispatch = useAppDispatch()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModalHandler = () => {
    setIsModalOpen(true)
  }

  const closeModalHandler = () => {
    setIsModalOpen(false)
  }

  const submit = (values: ChangePasswordDto, actions: unknown) => {
    dispatch(changePwdRequest(values))
  }

  return (
    <>
      <FormField label={label}>
        <BButton auto bordered text={text} onClick={openModalHandler} />
      </FormField>

      <ChangePasswordModal
        label={label}
        open={isModalOpen}
        type={type}
        closeHandler={closeModalHandler}
        submit={submit}
      />
    </>
  )
}
