import BInput from '@components/BInput'
import ChangePassword from '@components/ChangePassword/ChangePassword'
import { SubsectionWrapper } from '@components/SubsectionWrapper'
import { blocksIcons } from '@constants/icons'
import { PwdTypes } from '@models/dtos/ChangePassword'
import { Settings } from '@models/Settings'
import { FormikProps } from 'formik'
import { useIntl } from 'react-intl'

export default function AmazonAccount({
  formik,
  disabledInputs = false
}: {
  formik: FormikProps<Settings>
  disabledInputs: boolean
}) {
  const { formatMessage: f } = useIntl()

  return (
    <SubsectionWrapper className="w-full lg:w-[480px]">
      <div className="mb-4 flex gap-6">
        <p className="font-bold">{f({ id: 'Amazon account' })}</p>
      </div>
      <div className="relative grid grid-cols-1 gap-4">
        <BInput
          disabled={disabledInputs}
          label={f({ id: 'Amazon Phone number' })}
          name="profileSetting.phoneNumber"
          value={formik.values?.profileSetting?.phoneNumber}
          onChange={formik.handleChange}
          error={formik.errors?.profileSetting?.phoneNumber}
          contentLeft={blocksIcons.phone}
        />
        <BInput
          disabled={disabledInputs}
          label={f({ id: 'Amazon Email' })}
          name="profileSetting.amazonUsername"
          value={formik.values?.profileSetting?.amazonUsername}
          onChange={formik.handleChange}
          error={formik.errors?.profileSetting?.amazonUsername}
          contentLeft={blocksIcons.mail}
          type="email"
        />

        <ChangePassword
          label={f({ id: 'Amazon Password' })}
          text={f({ id: 'Change amazon password' })}
          type={PwdTypes.AmzPwd}
        />
      </div>
    </SubsectionWrapper>
  )
}
