import { Input, useInput } from '@nextui-org/react'
import { ChangeEvent, KeyboardEvent } from 'react'
import { BInputProps } from './BInput'

export default function InputExpiryDate({
  error,
  value,
  onChange,
  ...props
}: BInputProps) {
  const { reset, bindings } = useInput('')

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const modifiersKeys =
      /(Enter|Shift|Meta|Control|Alt|Backspace|Delete|Escape|Tab|Arrow)/

    const digits = /\d/

    if (!digits.test(e.key) && !modifiersKeys.test(e.key)) {
      e.preventDefault()
    }

    // Max 5 characters, format: MM/YY
    if (value.toString().length === 5 && !modifiersKeys.test(e.key)) {
      e.preventDefault()
    }

    // 0 > MM <= 12
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 2) {
      e.target.value += '/'
    }

    onChange(e)
  }

  return (
    <Input
      {...props}
      {...bindings}
      onClearClick={reset}
      color={error === undefined ? 'default' : 'error'}
      helperColor={error === undefined ? 'default' : 'error'}
      helperText={error}
      value={value}
      onChange={handleOnChange}
      onKeyDown={handleKeyDown}
    />
  )
}
