import TourProgressContent from '@components/ProductTour/TourProgress/TourProgressContent'
import { SubsectionWrapper } from '@components/SubsectionWrapper'
import { useIntl } from 'react-intl'

export default function TourProgressSettings() {
  const { formatMessage: f } = useIntl()

  return (
    <SubsectionWrapper className="w-full lg:w-[480px] min-h-[450px]">
      <div className="mb-4 flex gap-6">
        <p className="font-bold">{f({ id: 'Tour progression' })}</p>
      </div>
      <TourProgressContent showResetBtn={true} />
    </SubsectionWrapper>
  )
}
