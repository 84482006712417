export function GetDigit(number: number, ntn: number): number {
  return Math.floor((number / Math.pow(10, ntn)) % 10)
}

export function GetUnit(
  number: number,
  range: number,
  index: number | undefined = undefined
): number {
  const value = number % range

  if (index !== undefined && GetDigit(number, index) % 2 === 0) {
    return -value
  }

  return value
}

export function GetRandomColor(
  number: number,
  colors: string[],
  range: number
): string {
  return colors[number % range]
}

export function HashCode(name: string): number {
  let hash = 0
  for (let i = 0; i < name.length; i++) {
    const character = name.charCodeAt(i)
    hash = (hash << 5) - hash + character
    hash = hash & hash // Convert to 32bit integer
  }
  return Math.abs(hash)
}
