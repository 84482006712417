import AvatarMarble from '@components/AvatarMarble'
import BButton from '@components/bButton/BButton'
import SectionWidget from '@components/sectionWidget'
import AccountInformation from '@components/settings/AccountInformation'
import AmazonAccount from '@components/settings/AmazonAccount'
import Billings from '@components/settings/Billings'
import TourProgressSettings from '@components/settings/TourProgressSettings'
import { AVATAR_COLORS } from '@constants/colors'
import { Settings as SettingsModel } from '@models/Settings'
import { useAppDispatch, useAppSelector } from '@store/hooks'
import { settingsRequest } from '@store/settings/settings.slice'
import { Form, Formik, FormikProps } from 'formik'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Diff } from 'shared'
import * as yup from 'yup'

export default function Settings() {
  const { isLoading, settings } = useAppSelector((state) => state.settings)
  const session = useAppSelector((state) => state.auth.session)
  const dispatch = useAppDispatch()
  const { formatMessage: f } = useIntl()
  const [isEditMode, setIsEditMode] = useState(false)

  const handleSubmit = async (values: SettingsModel, actions: unknown) => {
    const diffValues = Diff<SettingsModel>(settings, values)
    setIsEditMode(false)

    // TODO: remove this, only for testing
    /* await Delay(1000) */

    await dispatch(settingsRequest(diffValues))
  }

  const handleSaveChanges = async (formik: FormikProps<SettingsModel>) => {
    if (isEditMode) {
      await formik.submitForm()
      return
    }
    setIsEditMode(true)
  }

  const submitButton = (formik: FormikProps<SettingsModel>) => {
    return (
      <>
        <BButton
          auto
          className="min-w-[130px]"
          isLoading={isLoading}
          text={!isEditMode ? f({ id: 'Edit' }) : f({ id: 'Save changes' })}
          onClick={async () => await handleSaveChanges(formik)}
        />
      </>
    )
  }

  return (
    <div id="settings">
      <div className="profile-header mb-[60px]">
        <h1 className="text-xl md:text-3xl">
          {f({ id: 'Setting your account' })}
        </h1>
      </div>
      <div className="settings-container">
        <SectionWidget
          title={f({ id: 'Configure your settings' })}
          className="mb-0 shadow-xl"
        >
          {settings !== undefined && (
            <Formik
              initialValues={settings}
              validationSchema={settingsFormSchema}
              onSubmit={handleSubmit}
            >
              {(formik) => (
                <Form className="w-full">
                  <div className="md:flex justify-between mb-6">
                    <div className="wrap-avatar flex items-center mb-4 md:mb-0">
                      <div className="relative mr-4">
                        <div className="md:w-20 md:h-20 w-16 h-16 rounded-full overflow-hidden">
                          <AvatarMarble
                            size={80}
                            name={session.name}
                            colors={AVATAR_COLORS}
                          />
                        </div>
                      </div>
                      <p className="text-2xl">{session.name}</p>
                    </div>
                    {submitButton(formik)}
                  </div>

                  <div className="flex flex-wrap gap-6">
                    <AccountInformation
                      disabledInputs={!isEditMode}
                      formik={formik}
                    />
                    <AmazonAccount
                      disabledInputs={!isEditMode}
                      formik={formik}
                    />
                    <Billings disabledInputs={!isEditMode} formik={formik} />
                  </div>
                  <div className="flex flex-wrap gap-6 mt-6">
                    <TourProgressSettings />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </SectionWidget>
      </div>
    </div>
  )
}

// TODO: fill all schema
const settingsFormSchema = yup.object({
  // name: yup.string().required('Name is required')
})
